import { useState, useEffect } from "react";
import axios from "axios";

import {
  Table,
  Fab,
  TableBody,
  Modal,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Button,
  IconButton,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";

export default function Products() {
  const [products, setProducts] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [newProduct, setNewProduct] = useState({
    name: "",
    price: "",
    image: "",
    qte: "",
  });
  const [editProduct, setEditProduct] = useState(null);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL +"products")
      .then((response) => setProducts(response.data))
      .catch((error) => console.error(error));
  }, []);

  const handleAddModalOpen = () => setShowAddModal(true);
  const handleAddModalClose = () => setShowAddModal(false);
  const handleEditModalOpen = (product) => {
    setShowEditModal(true);
    setEditProduct(product);
  };
  const handleEditModalClose = () => {
    setShowEditModal(false);
    setEditProduct(null);
  };
  const handleViewModalOpen = (product) => {
    setShowViewModal(true);
    setSelectedProduct(product);
  };
  const handleViewModalClose = () => {
    setShowViewModal(false);
    setSelectedProduct(null);
  };

  const handleNewProductChange = (event) => {
    const { name, value } = event.target;
    setNewProduct((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleEditProductChange = (event) => {
    const { name, value } = event.target;
    setEditProduct((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAddProduct = () => {
    axios
      .post(process.env.REACT_APP_API_URL +"products", newProduct)
      .then((response) => {
        setProducts((prevState) => [...prevState, response.data]);
        setNewProduct({ name: "", price: "",image :"",qte: "" });
        handleAddModalClose();
      })
      .catch((error) => console.error(error));
  };
  const handleEditProduct = () => {
    axios
      .put(process.env.REACT_APP_API_URL +`products/${editProduct.id}`, editProduct)
      .then((response) => {
        setProducts((prevState) =>
          prevState.map((product) =>
            product.id === response.data.id ? response.data : product
          )
        );
        handleEditModalClose();
      })
      .catch((error) => console.error(error));
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setProductToDelete(null);
  };

  const handleDeleteProduct = (productId) => {
    const productToDelete = products.find(
      (product) => product.id === productId
    );
    return axios
      .delete(process.env.REACT_APP_API_URL +`products/${productId}`)
      .then(() =>
        setProducts((prevState) =>
          prevState.filter((product) => product.id !== productId)
        )
      )
      .catch((error) => console.error(error));
  };

  const ProductDeleteDialog = ({
    open,
    handleClose,
    productId,
    handleDeleteProduct,
  }) => {
    const [loading, setLoading] = useState(false);

    const handleConfirmDelete = () => {
      setLoading(true);
      handleDeleteProduct(productId).finally(() => {
        setLoading(false);
        handleClose();
      });
    };

    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete Product</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            disabled={loading}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Typography variant="h3" align="center" gutterBottom>
        Products
      </Typography>

      <Box display="flex" justifyContent="flex-end" my={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddModalOpen}
        >
          Add Product
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="products table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Qte</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.id}>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.image}</TableCell>
                <TableCell>{product.price}</TableCell>
                <TableCell>{product.qte}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="view"
                    onClick={() => handleViewModalOpen(product)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleEditModalOpen(product)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setProductToDelete(product);
                      setShowDeleteModal(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {productToDelete && (
        <ProductDeleteDialog
          open={showDeleteModal}
          handleClose={handleDeleteModalClose}
          productId={productToDelete.id}
          handleDeleteProduct={handleDeleteProduct}
        />
      )}

      <Dialog open={showAddModal} onClose={handleAddModalClose}>
        <DialogTitle>Add Product</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            value={newProduct.name}
            onChange={handleNewProductChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Price"
            name="price"
            value={newProduct.price}
            onChange={handleNewProductChange}
            fullWidth
            margin="normal"
          />
            <TextField
            label="Image"
            name="image"
            value={newProduct.image}
            onChange={handleNewProductChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="qte"
            name="qte"
            value={newProduct.qte}
            onChange={handleNewProductChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddProduct} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showEditModal} onClose={handleEditModalClose}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            value={editProduct ? editProduct.name : ""}
            onChange={handleEditProductChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Price"
            name="price"
            value={editProduct ? editProduct.price : ""}
            onChange={handleEditProductChange}
            fullWidth
            margin="normal"
          />
           <TextField
            label="Image"
            name="image"
            value={editProduct ? editProduct.image : ""}
            onChange={handleEditProductChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Qte"
            name="qte"
            value={editProduct ? editProduct.qte : ""}
            onChange={handleEditProductChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditProduct} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showViewModal} onClose={handleViewModalClose}>
        <DialogTitle>
          {selectedProduct ? selectedProduct.name : ""}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="ID"
            value={selectedProduct ? selectedProduct.id : ""}
            fullWidth
            margin="normal"
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Title"
            value={selectedProduct ? selectedProduct.name : ""}
            fullWidth
            margin="normal"
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Price"
            value={selectedProduct ? selectedProduct.price : ""}
            fullWidth
            margin="normal"
            InputProps={{ readOnly: true }}
          />
                    <TextField
            label="Image"
            value={selectedProduct ? selectedProduct.image : ""}
            fullWidth
            margin="normal"
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Qte"
            value={selectedProduct ? selectedProduct.qte : ""}
            fullWidth
            margin="normal"
            InputProps={{ readOnly: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewModalClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
