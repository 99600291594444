import './App.css';
import { useEffect, useState } from "react";
import axios from "./axios";
import Table from 'react-bootstrap/Table'

function Model() {

  const [index, set_index] = useState(-1);
  const [menu, setMenu] = useState([]);
  const [menu1, setMenu1] = useState([]);
  const [orders, setOrders] = useState([]);
  const [detail_orders, set_detail_orders] = useState([]);

  useEffect(() => {
    // dbGetMenu();
    initOrders();
  }, []);

  function previousMenu() {
    var i = index <= 0 ? index : index - 1;
    set_index(i);
    setMenu(menu1[i]?.children ?? []);
    if (index > 0) setMenu1(Object.values(menu1).slice(0, -1));
    // console.log(Object.values(menu1).slice(0, 0));
  }

  const nextMenu = (data = null, index1 = null) => {
    set_index(index1);
    setMenu1({ ...menu1, [index1]: data });
  }

  const dbGetMenu = () => {
    axios.get(process.env.REACT_APP_API_URL + 'products')
      .then((response) => {
        setMenu(response?.data);
        nextMenu({ children: response?.data }, 0);
      }).catch(() => {
        setMenu([]);
      });
  }

  const initMenu = () => {
    set_index(0);
    setMenu(menu1[0]?.children);
    setMenu1({ 0: menu1[0] });
  }

  const initOrders = () => {
    axios.get(process.env.REACT_APP_API_URL + 'orders')
      .then((response) => {
        setOrders(response?.data);
      }).catch(() => {
        setOrders([]);
      });
  }

  const onPress = async (menu) => {
    if (menu?.children?.length > 0) {
      setMenu(menu?.children);
      nextMenu(menu, index + 1);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    else {
      set_detail_orders([...detail_orders, { ...menu, image: menu1[1]?.image, name: menu1[1]?.name }]);
    }
  }

  const onDelete = async (table, id) => {
    if (window.confirm("Supprimer la commande")) {
      await axios.delete(process.env.REACT_APP_API_URL + `${table}/${id}`)
        .then((response) => {
          initOrders();
        })
      // .catch((err) => {
      //   alert(err);
      // });
    }
  }

  const onSave = async () => {
    await axios
      .post(process.env.REACT_APP_API_URL + `orders`, {
        products: detail_orders?.map(item => ({ product_id: item?.id }))
      })
      .then((response) => {
        initOrders();
      })
    // .catch((err) => {
    //   alert(err);
    // });

    set_detail_orders([]);
    initMenu();
  }

  return (
    <div className='mb-5 mt-0'>

      <h1 className='mt-5' style={{ textAlign: 'center' }}>Commandes</h1>

      <Table className='' style={{ marginBottom: 150 }}>
        <thead>
          <tr>
            <th className=''>#</th>
            <th className=''>Temps</th>
            {/* <th className=''>Titre</th> */}
            {/* <th className=''>Quantité</th> */}
            <th className=''>Prix</th>
          </tr>
        </thead>
        <tbody>
          {orders && orders.length > 0 && orders.map((order) =>
            <>
              <tr key={order?.id} onClick={() => onDelete('orders', order?.id)} style={{ backgroundColor: '#ddd' }}>
                <th className=''>{order?.id}</th>
                <td className=''>{order?.created_at_string}</td>
                <td className=''>{order?.price} DH</td>
              </tr>
              {order?.order_details && order?.order_details.length > 0 && order?.order_details.map((order_detail) =>
                <tr key={order_detail?.id} onClick={() => onDelete('order_details', order_detail?.id)} style={{ backgroundColor: '#fff' }}>
                  <td colSpan={2} style={{ fontSize: 14 }}>{order_detail?.product_name}</td>
                  <td>{order_detail?.product_price} DH</td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>
    </div >
  );
}

export default Model;
