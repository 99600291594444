import axios from "axios";

// axios.defaults.headers.common = {
//     'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
//     'Accept': 'application/json',
// };

// axios.interceptors.response.
//     use(function (response) {
//         return response;
//     }, function (err) {
//         if (err?.response?.status == 401) {
//             // localStorage.setItem("accessToken", '');
//             // window.location.replace(process.env.PUBLIC_URL);
//         }
//         return err;
//     });

export default axios;
