import './App.css';
import { useState } from "react";
import axios from "axios";
import moment from "moment";
import Menu from "./Menu";

function Model({ changeRoute }) {

  const [is_loading, set_is_loading] = useState(false);

  const Login = async () => {
    set_is_loading(true);
    await axios.post(process.env.REACT_APP_API_URL + 'login', {
      email: username,
      password
    })
      .then((res) => {
        if (res?.data?.access_token != undefined) {
          localStorage.setItem("accessToken", res?.data?.access_token);
          localStorage.setItem("user", JSON.stringify(res?.data?.user ?? {}));
          localStorage.setItem("company", JSON.stringify(res?.data?.company ?? {}));
          localStorage.setItem("expiresIn", moment().add(res?.data?.expires_in, "s"));
          changeRoute(<Menu />);
        } else {
          alert('Email or Password is incorrect!');
        }
      })
      .catch((err) => {
        alert(err);
      });
    set_is_loading(false);
  }

  const [username, set_username] = useState('');
  const [password, set_password] = useState('');

  return (
    <div className='mt-4' style={{ borderColor: 'black', width: '100%', height: '100%' }}>

      {/* <h1 style={{ textAlign: 'center' }}>Login</h1>

      <label>Username:
        <input type="text" value={username || ""} onChange={(e) => set_username(e.target.value)} />
      </label>
      <label>Password:
        <input type="password" value={password || ""} onChange={e => set_password(e.target.value)} />
      </label>
      <button onClick={Login} type="submit" >Connect</button> */}

      <div className="container">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card shadow-2-strong">
              <div className="card-body p-5 text-center">

                <h3 className="mb-5">Sign in</h3>

                <div className="form-outline mb-4">
                  <label className="form-label" for="typeEmailX-2">Email</label>
                  <input type="email" id="typeEmailX-2" className="form-control form-control-lg" onChange={(e) => set_username(e.target.value)} />
                </div>

                <div className="form-outline mb-4">
                  <label className="form-label" for="typePasswordX-2">Password</label>
                  <input type="password" id="typePasswordX-2" className="form-control form-control-lg" onChange={(e) => set_password(e.target.value)} />
                </div>

                <div className="form-check d-flex justify-content-start mb-4">
                  <input className="form-check-input" type="checkbox" value="" id="form1Example3" />
                  <label className="form-check-label" for="form1Example3"> Remember password </label>
                </div>

                <button className="btn btn-primary btn-lg btn-block" onClick={async () => await Login()} type="submit" disabled={is_loading}>Login{is_loading && '...'}</button>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div >
  );
}

export default Model;
