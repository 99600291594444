import axios from 'axios';

const URL = process.env.REACT_APP_API_URL;

class Service {

    // Users
    getUsers() {
        return axios.get(URL + 'users');
    }

    createUser(user) {
        return axios.post(URL + 'users', user);
    }

    getUserId(userId) {
        return axios.get(URL + 'users' + '/' + userId);
    }

    updateUser(user, userId) {
        return axios.put(URL + 'users' + '/' + userId, user);
    }

    deleteUser(userId) {
        return axios.delete(URL + 'users' + '/' + userId);
    }

    // Product
    getProducts() {
        return axios.get(URL + 'products');
    }

    createProduct(Product) {
        return axios.post(URL + 'products', Product);
    }

    getProductId(ProductId) {
        return axios.get(URL + 'products' + '/' + ProductId);
    }

    updateProduct(Product, ProductId) {
        return axios.put(URL + 'products' + '/' + ProductId, Product);
    }

    deleteProduct(ProductId) {
        return axios.delete(URL + 'products' + '/' + ProductId);
    }

}

export default new Service()