import TreeItem from '@mui/lab/TreeItem';
import TreeItemLoop from './TreeItemLoop';

export default function (object) {
    return (
        object?.object?.length > 0 && object?.object?.map(obj => {
            return (
                <TreeItem className='my-1' nodeId={obj?.id} label={<span>{obj?.name} - {<span className='px-2 py-0 rounded text-light' style={{ backgroundColor: obj?.total ? '#198754' : '#dc3545' }}> {obj?.total ?? 0} DH</span>}</span>}>
                    {obj?.children?.length > 0 && <TreeItemLoop object={obj?.children} />}
                </TreeItem>
            )
        })
    )
};
