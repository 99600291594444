import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import Button from '@mui/material/Button';
import { useState, React,useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Service from './Service';

function Users() {

    const [users, setUsers] = useState([]);
    const [user, setUser] = useState([]);
    const [isSave, setisSave] = useState(false);
    const [isEdit, setisEdit] = useState(false);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {
        initUsers();
      }, []);
      
    const initUsers = () => {
        Service.getUsers()
        .then((response) => {
            setUsers(response?.data);
          }).catch(() => {
            setUsers([]);
          });
        };

    const onClickSave =event =>
    {
        alert("Click");
        alert(user?.name);
        alert(user?.Email);
        alert(user?.password);
        if(isSave)
            Service.createUser(user);
    };  

    const handleChangeName = event => { 
        user.name=event.target.value; 
      }
      const handleChangeMail = event => {
        user.Email=event.target.value;
      }
      const handleChangePass = event => {
        user.password=event.target.value;
      }
    
    if (isEdit) {
        return (
            <div >
                <div id="Edit">
                    <Box component="span" sx={{backgroundColor: '#1976d2',color:"white" }} display="flex" justifyContent="space-between" alignItems="center">
                        <span className="bi bi-arrow-left text-light float-left d-inline d-sm-none"  onClick={e => { setisEdit(false); }}><ArrowBackRoundedIcon /></span>
                        <button  onClick={e => { setisEdit(false); }} className="btn btn-light float-left d-sm-inline d-none mt-2" ><ArrowBackRoundedIcon /> Back</button>
                        <h1>Utilisateur</h1>
                        <span className="bi bi-arrow-left text-light float-left d-inline d-sm-none" onClick={e => { setisSave(!isSave); }}>{isSave ? <SaveRoundedIcon /> : <EditRoundedIcon />}</span>
                        <button  className="btn btn-light float-left d-sm-inline d-none mt-2" onClick={e => { setisSave(!isSave); onClickSave();}}> {isSave ? <SaveRoundedIcon /> : <EditRoundedIcon />}</button>
                    </Box>
                    <Box component="form" 
                    sx={{ '& .MuiTextField-root': { m: 1 } }}
                        noValidate autoComplete="off" alignItems="center"
                        style={{
                            position: 'absolute', left: '50%',
                            transform: 'translate(-50%)'
                        }}>
                        <div>

                            <div>
                                <TextField id="filled-size-small" label="Name" defaultValue={user?.name}  onChange={handleChangeName} variant="filled" disabled={!isSave} fullWidth />
                                <TextField id="filled-size-small" label="Email" defaultValue={user?.Email} onChange={handleChangePass}  variant="filled" disabled={!isSave} fullWidth />
                            </div>
                            <div>
                                <TextField id="filled-size-small" label="Password" defaultValue={user?.password} onChange={handleChangeMail}   type="Password" disabled={!isSave} variant="filled" fullWidth />
                                {/* <TextField id="filled-size-small" label="Confirm Password" defaultValue={user?.password}  type="Password" disabled={!isSave} variant="filled" fullWidth /> */}
                            </div>
                            <div>
                                <Box component="span" m={2} display="flex" justifyContent="space-between" alignItems="center">
                                    <Button variant="contained" style={{ width: '100%' }} color="error" disabled={!isSave} startIcon={<DeleteIcon />}>Delete</Button>
                                </Box>
                            </div>
                        </div>
                    </Box>
                </div>


            </div>
        );
    }

    else {
        return (
            <div >
               <Box component="span" 
                 sx={{backgroundColor: '#1976d2',color:"white" }} 
                 display="flex" justifyContent="space-between" alignItems="center">
                        <span className="bi bi-arrow-left text-light float-left d-inline d-sm-none"><ArrowBackRoundedIcon /></span>
                        <button className="btn btn-light float-left d-sm-inline d-none mt-2" ><ArrowBackRoundedIcon /> Back</button>
                        <h1>Utilisateurs</h1>
                        <span className="bi bi-arrow-left text-light float-left d-inline d-sm-none" onClick={e => { setisEdit(true); }}><AddIcon /></span>
                        <button className="btn btn-light float-left d-sm-inline d-none mt-2" onClick={e => { setisEdit(true); setisSave(true); }}><AddIcon /> Ajouter</button>
                    </Box>
                <div>
                    
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell align="right">Nom</TableCell>
                                    <TableCell align="right">Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {users && users.length > 0 && users.map((res) => (
                                    <TableRow key={res?.Id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row"> {res?.Id} </TableCell>
                                        <TableCell align="right">{res?.Nom}</TableCell>
                                        <TableCell align="right">{res?.Email}</TableCell>
                                    </TableRow>
                                ))}
                                {/* <TableRow key={'test'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} onClick={() => setisEdit(true)}>
                                    <TableCell component="th" scope="row"> 1 </TableCell>
                                    <TableCell align="right">houda</TableCell>
                                    <TableCell align="right">houda@gmail.com</TableCell>
                                </TableRow>
                                <TableRow key={'test'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} onClick={e => alert(e.textContent)}>
                                    <TableCell component="th" scope="row"> 2 </TableCell>
                                    <TableCell align="right">houda</TableCell>
                                    <TableCell align="right">houda@gmail.com</TableCell>
                                </TableRow>
                                <TableRow key={'test'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row"> 3 </TableCell>
                                    <TableCell align="right">houda</TableCell>
                                    <TableCell align="right">houda@gmail.com</TableCell>
                                </TableRow>
                                <TableRow key={'test'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row"> 4 </TableCell>
                                    <TableCell align="right">houda</TableCell>
                                    <TableCell align="right">houda@gmail.com</TableCell>
                                </TableRow> */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        );
    }
}
export default Users;