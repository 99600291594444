import './App.css';
import { useEffect } from "react";
import axios from "./axios";
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import TreeItemLoop from './TreeItemLoop';
import { CircularProgress } from '@mui/material';
import moment from 'moment';

function Model() {

  useEffect(() => {
    initDash();
  }, []);

  const [expanded, setExpanded] = React.useState([]);
  const [dash, set_dash] = React.useState([]);
  const [products, set_products] = React.useState([]);
  const [total, set_total] = React.useState([]);
  const [loading, set_loading] = React.useState(false);
  const [date, set_date] = React.useState(moment().format('YYYY-MM-DD'));

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0 ? (products ?? []) : [],
    );
  };

  const initDash = async (date = null) => {
    set_loading(true);
    await axios
      .get(process.env.REACT_APP_API_URL + 'dash', {
        params: { date: moment(date ?? undefined).format('YYYY-MM-DD') }
      })
      .then((response) => {
        set_dash(response?.data?.data);
        set_products(response?.data?.products);
        set_total(response?.data?.total);
      })
      .catch(() => {
        set_dash([]);
      });
    set_loading(false);
  }

  return (
    <div>

      <h1 className='mt-3 mb-5' style={{ textAlign: 'center' }}>Dashboard</h1>

      <div className='mt-0 border border-2 pb-3'>

        <div className='col-12 col-sm-6 col-md-3 mt-3 px-3'>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text">Date</div>
            </div>
            <input className='form-control' type="date" value={date} onChange={(event) => { initDash(event?.target?.value); set_date(event?.target?.value) }} for="inlineFormInputGroup" />
          </div>
        </div>

        <Box sx={{ flexGrow: 1, overflowY: 'auto', marginTop: 3 }} className='col-12 col-sm-6 col-md-4'>
          <Box sx={{ mb: 1 }}>
            <Button onClick={handleExpandClick}>
              {expanded.length === 0 ? 'Expand all' : 'Collapse all'}
            </Button>
          </Box>
          <TreeView
            aria-label="controlled"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            // selected={selected}
            onNodeToggle={handleToggle}
          // onNodeSelect={handleSelect}
          // multiSelect
          >
            {loading && <div className='text-center pb-3'><CircularProgress /></div>}
            {!loading && <>
              <div className='px-3 pb-2'>
                <span className='form-control text-center'>Total : {total ?? 0} DH</span>
              </div>
              <TreeItemLoop object={dash} />
            </>}

          </TreeView>
        </Box>

      </div>

    </div>
  );
}

export default Model;
