import "./App.css";
import ReactDOM from "react-dom/client";
import { useEffect, useState } from "react";
import React, { Fragment } from "react";

import axios from "./axios";
// import Table from "react-bootstrap/Table";
import {
  Card,
  CardMedia,
  Button,
  CardContent,
  List,
  Grid,
  ListItem,
  Toolbar,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Checkbox,
  Box,
} from "@mui/material";
import {
  KeyboardBackspaceRounded,
  CachedRounded,
  SaveRounded,
} from "@mui/icons-material";


import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { ExitToApp } from "@mui/icons-material";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MakeMenu from "./MakeMenu";

function Menu() {
  const [index, set_index] = useState(-1);
  const [menu, setMenu] = useState([]);
  const [menu1, setMenu1] = useState([]);
  const [order, set_order] = useState(null);
  const [orders, setOrders] = useState([]);
  const [detail_orders, set_detail_orders] = useState([]);
  const [user, set_user] = useState(JSON.parse(localStorage.getItem("user")));
  const [company, set_company] = useState(
    JSON.parse(localStorage.getItem("company"))
  );

  useEffect(() => {
    dbGetMenu();
    initOrders();
  }, []);

  function previousMenu() {
    var i = index <= 0 ? index : index - 1;
    set_index(i);
    setMenu(menu1[i]?.children ?? []);
    if (index > 0) setMenu1(Object.values(menu1).slice(0, -1));
    // console.log(Object.values(menu1).slice(0, 0));
  }

  const nextMenu = (data = null, index1 = null) => {
    set_index(index1);
    setMenu1({ ...menu1, [index1]: data });
  };

  const dbGetMenu = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "menus")
      .then((response) => {
        setMenu(response?.data);
        nextMenu({ children: response?.data }, 0);
      })
      .catch(() => {
        setMenu([]);
      });
  };
  let sum = 0;

  // this the printing ticket
  const print = async (order) => {
    set_order(order);

    const frame1 = document.createElement("iframe");
    frame1.name = "frame1";
    frame1.style.position = "absolute";
    document.body.appendChild(frame1);
    const frameDoc =
      frame1.contentWindow ||
      frame1.contentDocument.document ||
      frame1.contentDocument;
    frameDoc.document.open();
    frameDoc.document.write(`
    <html>
      <head>
        <title>Order Details</title>
        <style>
          body {
            font-family: 'Montserrat', sans-serif;
          }
          table {
            border-collapse: collapse;
            width: 100%;
          }
          th, td {
            text-align: left;
            padding: 8px;
          }
          th {
            background-color: #f2f2f2;
            font-weight: 600;
          }
          .item {
            width: 60%;
          }
          .rate {
            width: 40%;
          }
          .tabletitle td {
            padding-bottom: 10px;
          }
          .service td {
            border-top: 1px solid #ddd;
          }
          .service:last-child td {
            border-bottom: 1px solid #f2f2f2;
          }
          .tableitem {
            width: 60%;
          }
          .itemtext {
            white-space: nowrap;
          }
          .price {
            text-align: right;
            width: 20%;
          }
          .total td {
            border-top: 2px solid #000;
            font-weight: bold;
          }
          .logo {
            width: 80px;
            height: 80px;
            margin-left: auto;
            margin-right: auto;
            display: block;
          }
          .company-name {
            font-size: 28px;
            font-weight: 700;
            text-align: center;
            margin-top: 10px;
            margin-bottom: 0;
          }
          .order-details {
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            margin-top: 5px;
            margin-bottom: 20px;
          }
          .footer {
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            margin-top: 20px;
          }
        </style>
      </head>
      <body>
        <img src="${process.env.REACT_APP_URL + company?.logo}" alt="${process.env.REACT_APP_URL + company?.logo
      }" class="logo">
        <h1 class="company-name">${company?.name}</h1>
        <p class="order-details">Date ${order?.created_at_string} | Nº ${order?.id
      }</p>
        <table>
          <tr class="tabletitle">
            <th class="item">Product</th>
            <th class="rate" style="text-align: right;">Price</th>
          </tr>
          ${order?.order_details
        ?.map(
          (o) => `
              <tr class="service">
                <td class="tableitem" style="text-align: left;">
                  <p class="itemtext">${o?.product_name ?? ""}</p>
                </td>
                <td class="rate" style="text-align: right;">${o?.product_price
            } DH</td>
              </tr>
            `
        )
        .join("")}
          <tr class="total">
            <td style="text-align: left; font-weight: bold;">Total</td>
            <td class="price" style="text-align: right;">${order?.price} DH</td>
          </tr>
        </table>
        <div class="footer">
          <p>WIFI : ${company?.if}</p>
          <p>Nom Serveur : ${user?.name}</p>
          <p>MERCI POUR VOTRE VISITE</p>
        </div>
      </body>
    </html>
    `);

    frameDoc.document.close();

    setTimeout(function () {
      window.frames["frame1"].focus();
      window.frames["frame1"].print();
      document.body.removeChild(frame1);
    }, 500);
  };

  const initMenu = () => {
    set_index(0);
    setMenu(menu1[0]?.children);
    setMenu1({ 0: menu1[0] });
  };

  const initOrders = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "orders", {
        params: { limit: 20 },
      })
      .then((response) => {
        setOrders(response?.data);
      })
      .catch(() => {
        setOrders([]);
      });
  };

  const onPress = async (menu) => {
    if (menu?.children?.length > 0) {
      setMenu(menu?.children);
      nextMenu(menu, index + 1);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    } else {
      set_detail_orders([
        ...detail_orders,
        {
          ...menu,
          image: (index == 0 ? menu : menu1[1])?.image,
          name: (index == 0 ? menu : menu1[1])?.name,
        },
      ]);
    }
  };

  const onDelete = async (table, id) => {
    if (window.confirm("Supprimer la commande")) {
      await axios
        .delete(process.env.REACT_APP_API_URL + `${table}/${id}`)
        .then((response) => {
          initOrders();
        });
      // .catch((err) => {
      //   alert(err);
      // });
    }
  };

  const onSave = async () => {
    await axios
      .post(process.env.REACT_APP_API_URL + `orders`, {
        products: detail_orders?.map((item) => ({ product_id: item?.id })),
      })
      .then((response) => {
        initOrders();
      });
    // .catch((err) => {
    //   alert(err);
    // });

    set_detail_orders([]);
    initMenu();
  };

  const handleRemoveItem = (index) => {
    set_detail_orders((prevOrders) =>
      prevOrders.filter((order, i) => i !== index)
    );
  };

  const handleDragEnd = (result) => {
    // handle drag end event here
  };

  const [openOrders, setOpenOrders] = useState([]);

  const handleToggleOrder = (orderId) => {
    if (openOrders.includes(orderId)) {
      setOpenOrders(openOrders.filter((id) => id !== orderId));
    } else {
      setOpenOrders([...openOrders, orderId]);
    }
  };

  const isOrderOpen = (orderId) => openOrders.includes(orderId);


  return (
    <div className="mb-5 mt-0">
      {/* Start of the added Products  */}
      <div className="row m-0">
        {detail_orders?.length > 0 && detail_orders.map((child, index) => (
          <div key={child?.product_id + index} className="col-2 col-sm-1 text-center mt-3">
            <img src={process.env.REACT_APP_URL + (child?.product_image ?? "")}
              alt={child?.product_image} style={{ width: 50, height: 50 }}
              onClick={() => set_detail_orders(detail_orders?.filter((od, i) => i != index))}
            ></img>
            <div>{child?.product_name}</div>
            {child?.product_price > 0 && <div>{child?.product_price} DH</div>}
          </div>
        ))}
      </div>
      {/* End of the added Products  */}

      {/* start of the appbar with the menu and return and save  */}


      <Toolbar style={{ backgroundColor: "#1976d2" }}>
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={previousMenu}
            className="text-light float-left d-inline d-sm-none"
          >
            <KeyboardBackspaceRounded />
          </IconButton>
          <Button
            onClick={previousMenu}
            variant="outlined"
            className="text-light float-left d-sm-inline d-none"
          >
            <KeyboardBackspaceRounded /> Back
          </Button>
        </div>
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={initMenu}
            className="text-light float-left d-inline d-sm-none"
          >
            <CachedRounded />
          </IconButton>
          <Button
            onClick={initMenu}
            variant="outlined"
            className="text-light float-left d-sm-inline d-none"
          >
            <CachedRounded /> Reload
          </Button>
        </div>
        <Typography
          variant="h5"
          style={{
            color: "#fff",
            flexGrow: 1,
            textAlign: "center",
            fontFamily: "Roboto",
            fontSize: "1.75rem",
            fontWeight: 500,
          }}
        >
          Menu
        </Typography>
        {detail_orders?.length > 0 && (
          <div
            style={{ height: "100%", display: "flex", alignItems: "center" }}
            onClick={onSave}
          >
            <Button
              variant="outlined"
              className="text-light btn-round float-right d-sm-inline d-none"
            >
              Save <SaveRounded style={{ marginLeft: "0.25rem" }} />
            </Button>
            <IconButton className="text-light float-right d-inline d-sm-none">
              <SaveRounded style={{ fontSize: 30 }} />
            </IconButton>
          </div>
        )}
      </Toolbar>
      {/* End of the appbar with the menu and return and save  */}

      {/* Start of the cards */}
      <div className="row m-0">
        {menu &&
          menu.length > 0 &&
          menu.map((child) => (
            <div
              key={child?.product_id}
              className="col-6 col-sm-4 col-md-2 text-center mt-3"
              onClick={() => onPress(child)}
            >
              <Card style={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  alt={child?.product_image}
                  height={200}
                  image={process.env.REACT_APP_URL + child?.product_image}
                  title={child?.product_name}
                  style={{ objectFit: "cover", filter: "blur(1.3px)" }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{
                      textAlign: "center",
                      fontWeight: 700,
                    }}
                  >
                    {child?.product_name}
                  </Typography>
                  {child?.product_price > 0 && (
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      style={{
                        textAlign: "center",
                        fontWeight: 700,
                        textShadow: "#000 1px 1px 10px",
                      }}
                    >
                      {child?.product_price} DH
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </div>
          ))}
      </div>
      {/* End of the cards */}

      {/* Start of the Commande with the table */}
      <h1 className="mt-5" style={{ textAlign: "center" }}>
        Commandes
      </h1>
      

      <Box sx={{ margin: "40px auto", width: "90%", pb:10 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Temps</TableCell>
                <TableCell>Prix</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {orders && orders.length > 0 &&
                orders.map((order) => (
                  <React.Fragment key={order?.id}>
                    <TableRow hover onClick={() => handleToggleOrder(order?.id)}>
                      <TableCell>{order?.id}</TableCell>
                      <TableCell>{order?.created_at_string}</TableCell>
                      <TableCell>{order?.price} DH</TableCell>
                      <TableCell>
                        <IconButton onClick={() => onDelete("orders", order?.id)}>
                          <DeleteIcon color="secondary" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => print(order)}>
                          <PrintRoundedIcon color="primary" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleToggleOrder(order?.id)}
                        >
                          {isOrderOpen(order?.id) ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    {isOrderOpen(order?.id) && order?.order_details && order?.order_details.length > 0 &&
                      order?.order_details.map((order_detail) => (
                        <TableRow key={order_detail?.id} hover>
                          <TableCell colSpan={2}>{order_detail?.product_name}</TableCell>
                          <TableCell>
                            {order_detail?.product_price} DH
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() =>
                                onDelete("order_details", order_detail?.id)
                              }
                            >
                              <DeleteIcon color="secondary" />
                            </IconButton>
                          </TableCell>
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      ))}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* End of the Commande with the table */}
    </div>
  );
}

export default Menu;
