import { useState } from "react";

import './App.css';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Menu from './Menu'
// import Menu from './Menucopy'
import Dash from './Dash'
import Orders from './Orders'
import Login from './Login'
import Users from './Users';
import Products from './Products';
import MakeMenu from './MakeMenu';
import axios from "axios";
import { Box, IconButton, AppBar, Toolbar, Divider, Avatar, MenuItem, Fab, Menu as MuiMenu, ListItemIcon } from "@mui/material";
import { PersonRounded, PersonAddAlt1Rounded, DashboardRounded, MenuBookTwoTone, ShoppingCartOutlined,MenuTwoTone, Logout, PeopleAltRounded, } from "@mui/icons-material";

import Profile from "./Profile";

function App() {

  const changeRoute = (model) => {
    var accessToken1 = localStorage.getItem("accessToken");
    setAccessToken(accessToken1);
    setModel(model);
    set_company(JSON.parse(localStorage.getItem("company")));
    set_user(JSON.parse(localStorage.getItem("user")));
  }

  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"));
  const [Model, setModel] = useState(localStorage.getItem("accessToken") ? <Menu /> : <Login changeRoute={changeRoute} />);
  const [anchorEl, setAnchorEl] = useState(null);
  const [company, set_company] = useState(JSON.parse(localStorage.getItem("company")));
  const [user, set_user] = useState(JSON.parse(localStorage.getItem("user")));
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 15,
    margin: '0 auto',
  });

  const StyledFab1 = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: -90,
    right: 0,
    margin: '0 auto',
  });

  const StyledFab2 = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 90,
    right: 0,
    margin: '0 auto',
  });

  axios.defaults.headers.common = {
    'Authorization': `Bearer ${accessToken}`,
    'Accept': 'application/json',
  };
  axios.interceptors.response.
    use(function (response) {
      return response;
    }, function (err) {
      if (err?.response?.status == 401) {
        setModel(<Login changeRoute={changeRoute} />);
        changeRoute(<Login changeRoute={changeRoute} />)
        document.getElementById('logout')?.click();
        localStorage.removeItem("accessToken");
        changeRoute(<Login changeRoute={changeRoute} />);
        setModel(<Login changeRoute={changeRoute} />);
      }
      else alert(err?.response?.data);
      return err;
    });

  return (
    <>
      <div id='logout' onClick={() => { localStorage.removeItem("accessToken"); changeRoute(<Login changeRoute={changeRoute} />) }}></div>

      <AppBar position="fixed" color="primary" className='py-1 rounded' sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar className="p-0 m-0">
          {accessToken ?
            <>
              <IconButton color="inherit" aria-label="open drawer" >
                <span className='h6'>{company?.name}</span>
              </IconButton>
              <StyledFab color="secondary" onClick={() => changeRoute(<Menu />)}>
                <MenuBookTwoTone />
              </StyledFab>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton className="mx-1" color="inherit" onClick={() => changeRoute(<Orders />)}>
                <ShoppingCartOutlined />
              </IconButton>
              <IconButton className="mx-1" color="inherit" onClick={() => changeRoute(<Dash />)}>
                <DashboardRounded />
              </IconButton>
              {/* Houda Cache*/}
              {/* <IconButton className="mx-1" color="inherit" onClick={() => changeRoute(<Users />)}>
                <PeopleAltRounded />
              </IconButton> */}
              {/* Houda Cache */}
              {/* <IconButton className="mx-1" color="inherit" onClick={() => changeRoute(<Products />)}>
                <ProductionQuantityLimitsRounded />
              </IconButton> */}
              <IconButton onClick={handleClick} aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
                <Avatar alt={user?.name} src={process.env.REACT_APP_URL + user?.profile_photo_path ?? 'avatar.jpg'} />
              </IconButton>
            </>
            :
            <>
            <Tooltip title="Login">
              <StyledFab1 color="secondary" onClick={() => changeRoute(<Login />)}>
                <PersonRounded />
              </StyledFab1>
            </Tooltip>
            <Tooltip title="Sign Up">
              <StyledFab2 color="secondary" onClick={() => changeRoute(<Login />)}>
                <PersonAddAlt1Rounded />
              </StyledFab2>
            </Tooltip>
              
              
            </>
          }
        </Toolbar>
      </AppBar>

      <MuiMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            m: 0,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {/* Houda Cache */}
        <MenuItem onClick={() => { changeRoute(<Profile />) }}>
          <Avatar alt={user?.name} src={process.env.REACT_APP_URL + user?.profile_photo_path ?? 'avatar.jpg'} /> {user?.name}
        </MenuItem>
        {/* <MenuItem>
          <Avatar alt={user?.name} src={process.env.REACT_APP_URL + user?.profile_photo_path ?? 'avatar.jpg'} /> {user?.name}
        </MenuItem> */}
        <Divider />
        <MenuItem onClick={() => changeRoute(<MakeMenu changeRoute={changeRoute} />)}>
          <ListItemIcon>
            <MenuTwoTone fontSize="small" />
          </ListItemIcon>
          MakeMenu
        </MenuItem>
        <MenuItem onClick={() => changeRoute(<Products changeRoute={changeRoute} />)}>
          <ListItemIcon>
            <MenuBookTwoTone fontSize="small" />
          </ListItemIcon>
          Products
        </MenuItem>
        <MenuItem onClick={() => changeRoute(<Users changeRoute={changeRoute} />)}>
          <ListItemIcon>
            <PeopleAltRounded fontSize="small" />
          </ListItemIcon>
          Users
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => { localStorage.removeItem("accessToken"); changeRoute(<Login changeRoute={changeRoute} />) }}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </MuiMenu>

      {Model}
    </>
  );
}

export default App;
