import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import { Box,Button,Grid,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,Paper,useMediaQuery,
        TextField } from '@mui/material';
        
import { useTheme } from '@mui/material/styles';
import Service from './Service';

function Profile() {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
    const [user, set_user] = React.useState(JSON.parse(localStorage.getItem("user")));
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleChangeName = event => { 
      user.name=event.target.value; 
    }
    const handleChangeMail = event => {
      user.Email=event.target.value;
    }
    const OnclickBtn =event =>{
      Service.updateUser(user,user?.id).then((response) => {
        localStorage.setItem("user", JSON.stringify(user ?? {}));
      }).catch(() => {
        alert("Error");
      });
    }
    return (
       
      //  <div>
      //       <Box sx={{ width: '100%', height: 200, backgroundColor: 'gray' }}>
                
      //      </Box>
      //      <div align="center" style={{marginTop:-50}} >
      //          <Avatar  sx={{ width: 100, height: 100 }} alt={user?.name} src={process.env.REACT_APP_URL + user?.profile_photo_path ?? 'avatar.jpg'} />
      //          <h4>{user?.name ?? ''}</h4>
      //          <Button variant="outlined" onClick={handleClickOpen}>Edit Profile</Button>
      //          <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      //              <DialogTitle id="responsive-dialog-title"> {"Edit Profile"} </DialogTitle>
      //              <DialogContent>
      //                  <Avatar  sx={{ width: 100, height: 100 }} alt={user?.name} src={process.env.REACT_APP_URL + user?.profile_photo_path ?? 'avatar.jpg'} />
      //                  <TextField id="standard-basic" label="Nom" defaultValue={user?.name} variant="standard" />
      //                  <br/>
      //                  <TextField id="standard-basic" label="Email" defaultValue={user?.Email} variant="standard" />
      //              </DialogContent>
      //              <DialogActions>
      //                  <Button onClick={handleClose} autoFocus>
      //                      Save
      //                  </Button>
      //                  <Button autoFocus onClick={handleClose}>
      //                      Close
      //                  </Button>
      //              </DialogActions>
      //          </Dialog>
      //      </div>          

      //  </div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item elevation={0}>  
              <Box sx={{ width: '100%', height: 100, backgroundColor: 'gray' }}>
              </Box>
              <div align="center" style={{marginTop:-50}} >
                <Avatar  sx={{ width: 100, height:  100}} alt={user?.name} src={process.env.REACT_APP_URL + user?.profile_photo_path ?? 'avatar.jpg'} />
                <h3 >{user?.name}</h3>
                {/* <br/> */}
                {/* <h3 >{user?.Email}</h3> */}
              </div>  
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item  elevation={0}><h3>General infotmation</h3></Item>
          </Grid>
          <Grid item xs={6}>
            <Item elevation={0}>
            <TextField fullWidth id="standard-basic" label="First Name" defaultValue={user?.name} onChange={handleChangeName} variant="filled" />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item elevation={0}>
            <TextField fullWidth id="standard-basic" label="Last Name" defaultValue={user?.name} variant="filled" />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item elevation={0}>
            <TextField fullWidth id="standard-basic" type={'email'} label="Email" defaultValue={user?.Email} onChange={handleChangeMail} variant="filled" />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item elevation={0} >
            <TextField fullWidth id="standard-basic" label="Phone" defaultValue={user?.Phone} variant="filled" />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item  elevation={0}>
            <Button variant="outlined" onClick={OnclickBtn}>Save All</Button> 
            </Item>
          </Grid>
        </Grid>
      </div>
    );
}
export default Profile;
        