import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,

} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
function CreateMenu() {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "products")
      .then((response) => setProducts(response.data))
      .catch((error) => console.error(error));
  }, []);

  const handleAddProduct = (product) => {
    setSelectedProducts((prevSelectedProducts) => {
      const lastSelectedProduct = prevSelectedProducts[prevSelectedProducts.length - 1];
      if (lastSelectedProduct === product) {
        // Do nothing if the last selected product is the same as the current product
        return prevSelectedProducts;
      } else if (!lastSelectedProduct) {
        // If there is no previously selected product, add the current product as the root
        return [{ product, children: [] }];
      } else {
        // Otherwise, add the current product as a child of the last selected product
        const newSelectedProduct = { product, children: [] };
        lastSelectedProduct.children.push(newSelectedProduct);
        return [...prevSelectedProducts, newSelectedProduct];
      }
    });
  };

  const renderTreeView = () => {
    if (selectedProducts.length === 0) {
      return null;
    }

    const renderTree = (products) => (
      <TreeItem key={products.product.id} nodeId={products.product.id} label={products.product.name}>
        {products.children.map((child) => renderTree(child))}
      </TreeItem>
    );

    return (
      <TreeView defaultCollapseIcon={<i className="fas fa-minus-square" />} defaultExpandIcon={<i className="fas fa-plus-square" />}>
        {selectedProducts.map((selectedProduct) => renderTree(selectedProduct))}
      </TreeView>
    );
  };

  return (
    <div style={{ padding: "16px" }}>
      <Box textAlign="center">
        <Typography variant="h4" gutterBottom>
          Create Menu
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {/* Left side */}
        <Grid item xs={12} sm={5}>
          <Paper style={{ padding: "16px", height: "100%", border: "1px solid" }}>
            <List>
              {products.map((product) => (
                <ListItem key={product.id} style={{ paddingRight: "72px" }}>
                  <ListItemText primary={product.name} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="add" onClick={() => handleAddProduct(product)}>
                      <AddIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
                {/* Right side */}
                <Grid item xs={12} sm={7}>
          <Paper style={{ padding: "16px", height: "100%", border: "1px solid" }}>
            {selectedProducts.length > 0 ? (
              <TreeView defaultCollapseIcon={<i className="fas fa-minus-square" />} defaultExpandIcon={<i className="fas fa-plus-square" />}>
                <TreeItem key={selectedProducts[0].product.id} nodeId={selectedProducts[0].product.id} label={selectedProducts[0].product.name}>
                  {selectedProducts.slice(1).map((selectedProduct) => (
                    <TreeItem key={selectedProduct.product.id} nodeId={selectedProduct.product.id} label={selectedProduct.product.name} />
                  ))}
                </TreeItem>
              </TreeView>
            ) : (
              <Typography variant="body1">No products selected.</Typography>
            )}
          </Paper>
        </Grid>


      </Grid>
    </div>
  );
}

export default CreateMenu;